$scrollbar-color: rgb(102, 102, 102);

// overflow
.overflowY {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-color;
}

/* scrollbar for Chrome */
::-webkit-scrollbar {
  width: 4px;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: $scrollbar-color;
}
