/* KEYS */
/* LIGHT THEME */
/* DARK THEME */

/*


This code is not actually understood, but it works. If youre changing this, good luck, and pray angular material
hasnt updated again to waste a week of time.


*/

@use 'sass:map';
@use '@angular/material' as mat;
@import './colours';

@include mat.core();

// FONT IMPORTS

@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

$dark-text: #393d3f;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$light-text: #f8f7ff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-typography: mat.m2-define-typography-config(
  $font-family: 'Inter',
  $button: mat.m2-define-typography-level(13px, 15px, 500),
);

$mat-primary: (
  50: #eeefff,
  100: #d4d7fe,
  200: #b8bdfe,
  300: #9ca2fe,
  400: #868efd,
  500: #717afd,
  600: #6972fd,
  700: #5e67fc,
  800: #545dfc,
  900: #424afc,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #e5e5e5,
  ),
);

$mat-warn: (
  50: #ffe8ee,
  100: #fec6d4,
  200: #fea0b8,
  300: #fe799b,
  400: #fd5d85,
  500: #fd4070,
  600: #fd3a68,
  700: #fc325d,
  800: #fc2a53,
  900: #fc1c41,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #e5e5e5,
  ),
);

$mat-accent: (
  50: #fffbea,
  100: #fff5ca,
  200: #ffefa7,
  300: #ffe984,
  400: #ffe469,
  500: #ffdf4f,
  600: #ffdb48,
  700: #ffd73f,
  800: #ffd236,
  900: #ffca26,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
  ),
);

$theme-primary: mat.m2-define-palette($mat-primary, 500);
$theme-accent: mat.m2-define-palette($mat-accent, 500);
$theme-warn: mat.m2-define-palette($mat-warn, 500);

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $mat-typography,
    density: 0,
  )
);

// DEFAULT STYLE EVERYTHING LIGHT
@include mat.all-component-themes($light-theme);
@include mat.typography-hierarchy($mat-typography);
$colour-config: mat.m2-get-color-config($light-theme);

/* LIGHT THEME */
.monek-light-theme {
  .mat-mdc-card {
    color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'primary'),
      '500-contrast'
    );
  }

  .main-panel {
    background-color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'background'),
      'dialog'
    );
    color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'primary'),
      '500-contrast'
    );
  }

  .inverse-panel {
    background-color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'primary'),
      '500-contrast'
    );
    color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'background'),
      'dialog'
    );
  }

  .mat-drawer-container,
  .bg-panel {
    background-color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'primary'),
      '900-contrast'
    );
    color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'primary'),
      'foregrond'
    );
  }

  .monek-nav-button:not(.no-interact).odin-active,
  .monek-nav-button:not(.no-interact):hover {
    background-color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'primary'),
      '500-contrast'
    );
    color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'primary'),
      '600-contrast'
    );
  }

  .interactable {
    cursor: pointer;
  }
  .interactable:hover {
    background-color: rgba(194, 194, 194, 0.3);
  }
  .interactable-text:hover {
    color: rgba(0, 0, 0, 0.3);
  }
  .bg-selected {
    background-color: #f8f9fa !important;
  }

  summary::after {
    content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLWRvd24iIGZpbGw9Im5vbmUiIGhlaWdodD0iMjQiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwb2x5bGluZSBwb2ludHM9IjYgOSAxMiAxNSAxOCA5Ii8+PC9zdmc+');
  }
  details[open] > summary::after {
    content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLXVwIiBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cG9seWxpbmUgcG9pbnRzPSIxOCAxNSAxMiA5IDYgMTUiLz48L3N2Zz4=');
  }
}

/* DARK THEME */
$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $mat-typography,
    density: 0,
  )
);

.monek-dark-theme {
  // Update components to use dark theme
  @include mat.all-component-colors($dark-theme);
  @include mat.all-component-typographies($dark-theme);
  $colour-config: mat.m2-get-color-config($dark-theme);

  .mat-mdc-card {
    color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'primary'),
      '600-contrast'
    );
  }

  .main-panel {
    background-color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'background'),
      'dialog'
    );
    color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'primary'),
      '600-contrast'
    );
  }

  .inverse-panel {
    background-color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'primary'),
      '600-contrast'
    );
    color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'background'),
      'dialog'
    );
  }

  .monek-nav-button:not(.no-interact).odin-active,
  .monek-nav-button:not(.no-interact):hover {
    background-color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'primary'),
      '600-contrast'
    );
    color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'primary'),
      '500-contrast'
    );
  }

  .table {
    color: white !important;
  }
  .table-striped > tbody > tr:nth-of-type(odd) {
    color: white !important;
  }

  .interactable {
    cursor: pointer;
  }
  .interactable:hover {
    background-color: rgba(32, 32, 32, 0.3);
  }
  .interactable-text:hover {
    color: rgba(255, 255, 255, 0.3);
  }
  .bg-selected {
    background-color: #313131 !important;
  }
  .bg-dark {
    background-color: white !important;
  }
  .bg-light {
    background-color: #6d6d6e !important;
  }
  .bg-white {
    background-color: black !important;
  }
  .text-dark {
    color: white !important;
  }
  .text-white {
    color: black !important;
  }
  .text-muted {
    color: #ececec !important;
  }

  summary::after {
    content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLWRvd24iIGZpbGw9Im5vbmUiIGhlaWdodD0iMjQiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwb2x5bGluZSBwb2ludHM9IjYgOSAxMiAxNSAxOCA5Ii8+PC9zdmc+');
  }
  details[open] > summary::after {
    content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLXVwIiBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cG9seWxpbmUgcG9pbnRzPSIxOCAxNSAxMiA5IDYgMTUiLz48L3N2Zz4=');
  }
}

/* GLOBAL THEME */

.bg-white-always:not(:disabled) {
  background-color: white !important;
}
.bg-muted-always:not(:disabled) {
  background-color: #bdbdbd !important;
}
.bg-dark-always:not(:disabled) {
  background-color: black !important;
}

//text
.mon-text-black {
  color: black !important;
}
.mon-text-white {
  color: white !important;
}
.mon-text-primary:not(:disabled) {
  color: #2a5585 !important;
}

.mon-text-success:not(:disabled) {
  color: #238142 !important;
}

.mon-text-warning:not(:disabled) {
  color: #a26300 !important;
}

.mon-text-danger:not(:disabled) {
  color: #9e4444 !important;
}

// backgrounds
.mon-bg-transparent:not(:disabled) {
  background-color: transparent !important;
}

.mon-bg-primary:not(:disabled) {
  background-color: #d1e7ff !important;
}

.mon-bg-success:not(:disabled) {
  background-color: #d1fbe7 !important;
}

.mon-bg-danger:not(:disabled) {
  background-color: #fbd1d1 !important;
}

.mon-bg-warning:not(:disabled) {
  background-color: #fbf5d1 !important;
}

// borders
.border.mon-border-primary:not(:disabled) {
  border-color: #2a5585 !important;
}

.border.mon-border-success:not(:disabled) {
  border-color: #238142 !important;
}

.border.mon-border-danger:not(:disabled) {
  border-color: #9e4444 !important;
}

.border.mon-border-warning:not(:disabled) {
  border-color: #a26300 !important;
}

//
.transaction-succeeded {
  color: #238142 !important;
}
.transaction-reversed {
  color: #a26300 !important;
}
.transaction-canceled {
  color: #9e4444 !important;
}
.transaction-in-progress {
  color: #a26300 !important;
}

.interactive:hover {
  cursor: pointer;
  opacity: 0.75;
}

.mat-mdc-raised-button,
.mat-mdc-stroked-button,
.mat-mdc-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-mdc-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-family: 'Material Icons', 'Material Icons';

  &.outline {
    font-family: 'Material Icons Outlined', 'Material Icons Outlined';
  }
  .mat-mdc-badge-content {
    font-family: 'Roboto';
  }
}

.mat-mdc-list-base mat-mdc-list-option.mat-mdc-list-item.mat-mdc-list-option {
  border-radius: 6px !important;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.monek-input {
  &.shadow.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper,
  &.shadow.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }

  &.flush.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper,
  &.flush.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    margin: 0;
    padding: 0;
  }

  &.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper,
  &.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    border-radius: 6px !important;
    padding: 0 0.75em 0 0.75em;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field-infix {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.form-item {
  .mat-mdc-form-field-flex {
    padding: 5px;
  }
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: #0009 !important;
}

.mat-mdc-form-field-flex .input-prefix-suffix {
  margin-left: 4px !important;
}

.large-modal {
  width: 100%;
  max-width: 700px !important;
}

.mat-mdc-form-field mat-hint.error {
  color: red;
}

// .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {

// }

@media only screen and (max-width: 768px) {
  .large-modal {
    max-width: 95vw !important;
  }
}

// .mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
//   padding-left: 16px !important;
// }

/* BOOTSTRAP */

.large-icon {
  width: 90px !important;
  height: 90px !important;
}

.w-fit {
  width: fit-content !important;
}

.h-fit {
  height: fit-content !important;
}

*:disabled {
  opacity: 0.5;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

details summary::-webkit-details-marker {
  display: none;
}

summary {
  display: flex !important;
  align-items: center !important;
}
summary::after {
  height: 24px;
  width: 24px;
  align-self: center;
  margin-right: 0;
  margin-left: auto;
}

.odin-active-small {
  cursor: pointer;
  border-right: 5px solid #f03;

  border-style: solid;
  border-image: linear-gradient(to bottom, #f03, #f4a12e) 1 100%;

  border-left: 0;
  border-top: 10px;
  border-bottom: 10px;
}

.monek-nav-button:not(.no-interact):hover {
  cursor: pointer;
  border-right: 5px solid #f03;

  border-style: solid;
  border-image: linear-gradient(to bottom, #f4a12e, #f03) 1 100%;

  border-left: 0;
  border-top: 0;
  border-bottom: 0;
}

.monek-nav-button.odin-active:not(.no-interact, .odin-sub-active) {
  cursor: pointer;
  border-right: 5px solid #f03;

  border-style: solid;
  border-image: linear-gradient(to bottom, #f03, #f4a12e) 1 100%;

  border-left: 0;
  border-top: 0;
  border-bottom: 0;
}

// summary::after {
//   content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAANVJREFUSEvt0zFuwkAQRuHPJyAVUCCFikuEgtCBuAgn4Aw5ATeBLqTIKdJFQijpkpoCtJILGznMUtBEnsbSjv0/zfNs4c5V3DlfCwgN/09FaaoOfsL56y884Ben6nGToiVeMMd7JmSMDVZYR4Ae3jDALAOSwrfYY4LvCJD6XbxiiEUJbBrmqQw/YIr0rNW1LYogYXgiRWv6FyQrPAfQpOsYacn5B5cq+9jhsWx84hlf0ZZFiqrfJ8hHeTDKCc9VVIWky5Qq+xLeMkFko7HfAkJtraJQ0RkTQCYZZk2hmgAAAABJRU5ErkJggg==');
// }
// details[open] > summary::after {
//   content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAANFJREFUSEvt0zFOgkEUReGPFWCnBYlWLsJQQIlxI66ANbgCdwI2BgpXYUdCEDptbTCTDAkh/LxnQUFg6jvnzNy813Lk0zoy30UQNnx+FV3VTr7DbmrgPxXd4LPeu8cyI8kKCnyC2wqdoZ+RZATXeMcdnvCLN3xVyeLQTyLBLnxaYQ9ZySFBE3zz4JSkSRDB05J9ggIvVXQwwEcwLV2MMUcPq+38PsEzXvCYgG9YRTLCEK+RoEjbSC9TBZYl/ME6EmT2J52JxjQNagpeBGGFp1/RHyhgJhm+qeWhAAAAAElFTkSuQmCC');
// }

// summary::after {
//     content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLWRvd24iIGZpbGw9Im5vbmUiIGhlaWdodD0iMjQiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwb2x5bGluZSBwb2ludHM9IjYgOSAxMiAxNSAxOCA5Ii8+PC9zdmc+");
//   }
//   details[open] > summary::after {
//     content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLXVwIiBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cG9seWxpbmUgcG9pbnRzPSIxOCAxNSAxMiA5IDYgMTUiLz48L3N2Zz4=");
//   }

/* EXTRA THEMES */

@import './trust-payments.scss';
