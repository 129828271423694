// mat stepper global rules
.mat-mdc-stepper-vertical {
  background: transparent;

  .mat-mdc-step-header {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }

  .mat-mdc-vertical-content {
    padding: 0 24px 12px 24px;
  }
}

.mat-mdc-drawer-backdrop.mat-mdc-drawer-shown {
  background-color: rgba(0, 0, 0, 0.2) !important;
  position: fixed;
}

// set background to transparent
mat-drawer-container,
.mat-mdc-drawer-container {
  background-color: transparent !important;
}

.mat-mdc-drawer-content {
  overflow-x: hidden !important;
}

/* global settings for mat stepper */
mat-stepper.timeline {
  .mat-mdc-vertical-content-container .mat-mdc-vertical-stepper-content {
    visibility: visible !important;
    height: auto !important;
  }

  .mat-mdc-step-header {
    pointer-events: none;
  }

  &.succeeded {
    .mat-mdc-step-header .mat-mdc-step-icon-state-edit,
    .mat-mdc-step-header .mat-mdc-step-icon-selected {
      background-color: $green !important;
    }
  }
  &.cancelled {
    .mat-mdc-step-header .mat-mdc-step-icon-state-edit,
    .mat-mdc-step-header .mat-mdc-step-icon-selected {
      background-color: $red !important;
    }
  }
  &.processing {
    .mat-mdc-step-header .mat-mdc-step-icon-state-edit,
    .mat-mdc-step-header .mat-mdc-step-icon-selected {
      background-color: $orange !important;
    }
  }
}
