/* global colours */
/* https://clrs.cc/ */

$navy: #001f3f;
$blue: #0074d9;
$aqua: #7fdbff;
$teal: #39cccc;
$purple: #b10dc9;
$fuchsia: #f012be;
$maroon: #85144b;
$red: #ff4136;
$orange: #ff851b;
$yellow: #ffdc00;
$olive: #3d9970;
$green: #2ecc40;
$lime: #01ff70;
$grey: #aaaaaa;
$silver: #dddddd;
$black: #111111;
$white: #ffffff;

/* custom colours */
$trans_background: #fafafc;
$mat_accent: #ff4081;
