.badge {
  display: inline-block;
  padding: 0.35rem 0.5rem 0.25rem 0.5rem;
  font-size: 1em;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
  text-transform: uppercase;

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }

  &_info {
    background-color: $orange;
    color: $white;
  }
  &_cancelled {
    background-color: $red;
    color: $white;
  }
  &_succeeded {
    background-color: $green;
    color: $white;
  }
}
