// Foreground Elements

// Light Theme Text
$dark-text: #272834;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #fbfbfb;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fbfbfb;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#272834, 20%);
$dark-bg-alpha-4: rgba(#272834, 0.04);
$dark-bg-alpha-12: rgba(#272834, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #272834;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fbfbfb, 0.04);
$light-bg-alpha-12: rgba(#fbfbfb, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Theme Config

body {
  --primary-color: #3e6374;
  --primary-lighter-color: #c5d0d5;
  --primary-darker-color: #284757;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
  main: #3e6374,
  lighter: #c5d0d5,
  darker: #284757,
  200: #3e6374,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #309d76;
  --accent-lighter-color: #c1e2d6;
  --accent-darker-color: #1e8159;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
  main: #309d76,
  lighter: #c1e2d6,
  darker: #1e8159,
  200: #309d76,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ba1a1a;
  --warn-lighter-color: #eababa;
  --warn-darker-color: #a30f0f;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #ba1a1a,
  lighter: #eababa,
  darker: #a30f0f,
  200: #ba1a1a,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);
$theme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
);

$trust-payments-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $mat-typography,
    density: 0,
  )
);

.monek-trust-payments-theme {
  @include mat.all-component-colors($trust-payments-theme);
  @include mat.all-component-typographies($trust-payments-theme);
  $colour-config: mat.m2-get-color-config($trust-payments-theme);

  // Specific component overrides, pieces that are not in line with the general theming

  // Handle buttons appropriately, with respect to line-height
  .mat-raised-button,
  .mat-stroked-button,
  .mat-flat-button {
    padding: 0 1.15em;
    margin: 0 0.65em;
    min-width: 3em;
    line-height: 36.4px;
  }

  .mat-standard-chip {
    padding: 0.5em 0.85em;
    min-height: 2.5em;
  }

  .material-icons {
    font-size: 24px;
    font-family: 'Material Icons', 'Material Icons';
    .mat-badge-content {
      font-family: 'Poppins';
    }
  }

  .mat-mdc-card {
    color: mat.m2-get-color-from-palette(
      map.get($colour-config, 'primary'),
      '500-contrast'
    );
  }

  .main-panel {
    background-color: #fff;
    color: #292d36;
  }

  .inverse-panel {
    background-color: #292d36;
    color: #fff;
  }

  .mat-drawer-container,
  .bg-panel {
    background-color: #e6e4e4;
    color: #292d36;
  }

  .monek-nav-button:not(.no-interact).odin-active,
  .monek-nav-button:not(.no-interact):hover {
    background-color: #292d36;
    color: #fff;
  }

  .interactable {
    cursor: pointer;
  }
  .interactable:hover {
    background-color: rgba(194, 194, 194, 0.3);
  }
  .interactable-text:hover {
    color: rgba(0, 0, 0, 0.3);
  }
  .bg-selected {
    background-color: #f8f9fa !important;
  }

  summary::after {
    content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLWRvd24iIGZpbGw9Im5vbmUiIGhlaWdodD0iMjQiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwb2x5bGluZSBwb2ludHM9IjYgOSAxMiAxNSAxOCA5Ii8+PC9zdmc+');
  }
  details[open] > summary::after {
    content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1jaGV2cm9uLXVwIiBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cG9seWxpbmUgcG9pbnRzPSIxOCAxNSAxMiA5IDYgMTUiLz48L3N2Zz4=');
  }

  $mon-primary: #309d76;
  $mon-accent: #3e6374;
  $mon-warn: #ba551a;
  $mon-danger: #ba1a1a;

  //text
  .mon-text-black {
    color: black !important;
  }
  .mon-text-white {
    color: white !important;
  }
  .mon-text-primary:not(:disabled) {
    color: $mon-primary !important;
  }

  .mon-text-success:not(:disabled) {
    color: $mon-accent !important;
  }

  .mon-text-warning:not(:disabled) {
    color: $mon-warn !important;
  }

  .mon-text-danger:not(:disabled) {
    color: $mon-danger !important;
  }

  // backgrounds
  .mon-bg-transparent:not(:disabled) {
    background-color: transparent !important;
  }

  .mon-bg-primary:not(:disabled) {
    background-color: lighten($mon-primary, 40%) !important;
  }

  .mon-bg-success:not(:disabled) {
    background-color: lighten($mon-accent, 40%) !important;
  }

  .mon-bg-danger:not(:disabled) {
    background-color: lighten($mon-warn, 40%) !important;
  }

  .mon-bg-warning:not(:disabled) {
    background-color: lighten($mon-danger, 40%) !important;
  }

  // borders
  .border.mon-border-primary:not(:disabled) {
    border-color: $mon-primary !important;
  }

  .border.mon-border-success:not(:disabled) {
    border-color: $mon-accent !important;
  }

  .border.mon-border-danger:not(:disabled) {
    border-color: $mon-danger !important;
  }

  .border.mon-border-warning:not(:disabled) {
    border-color: $mon-warn !important;
  }

  .odin-active-small {
    cursor: pointer;
    border-right: 5px solid #309d76;

    border-style: solid;
    border-image: linear-gradient(to bottom, #309d76, #e4f3ed) 1 100%;

    border-left: 0;
    border-top: 10px;
    border-bottom: 10px;
  }

  .monek-nav-button:not(.no-interact):hover {
    cursor: pointer;
    border-right: 5px solid #309d76;

    border-style: solid;
    border-image: linear-gradient(to bottom, #e4f3ed, #309d76) 1 100%;

    border-left: 0;
    border-top: 0;
    border-bottom: 0;
  }

  .monek-nav-button.odin-active:not(.no-interact, .odin-sub-active) {
    cursor: pointer;
    border-right: 5px solid #309d76;

    border-style: solid;
    border-image: linear-gradient(to bottom, #309d76, #e4f3ed) 1 100%;

    border-left: 0;
    border-top: 0;
    border-bottom: 0;
  }
}
/* TRUST PAYMENTS END */
