/* You can add global styles to this file, and also import other style files */
@import './core-imports';
@import './mat-overridden-styles';
@import './bootstrap-alerts-overridden';
@import './scrollbar';

@import url('https://fonts.googleapis.com/css2?family=Montserrat');
@font-face {
  font-family: 'ConthraxSb-Regular';
  src:
    url('/assets/fonts/conthraxsb-regular.woff2') format('woff2'),
    url('/assets/fonts/conthraxsb-regular.woff') format('woff');
}
@font-face {
  font-family: 'OCR-B';
  src:
    url('/assets/fonts/OCR-B.woff2') format('woff2'),
    url('/assets/fonts/OCR-B.woff') format('woff');
}

// @import '../../../../libs/odin-core/src/lib/styles/monek-styles.scss';

// GLOBAL USABILITY

.kb-scrollable {
  margin-bottom: 1000px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.page-height {
  height: 100%;
  max-height: calc(100dvh - 73px) !important;
  overflow: hidden; // stops scrolling of application
}

//

body {
  margin: 0;
  // font-family: montserrat !important;
  overflow-x: hidden;
  // -webkit-overflow-scrolling: touch
}

html,
body {
  height: 100dvh;
  max-height: 100dvh;
  overflow-x: hidden;
  overflow-y: hidden;
}

* {
  // user-select: none;
  box-sizing: border-box;
}

.printable-screen {
  display: none;
}

@media print {
  .printable-screen {
    display: block;
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    z-index: 1000000000000000;
  }

  .mat-drawer {
    display: none !important;
  }
}

// pages should use this to stop the header from scrolling
.full-height {
  max-height: 100%;
  height: 100%;
}

.monek-font {
  font-family: ConthraxSb-Regular, montserrat !important;
}

.card-font {
  font-family: OCR-B !important;
}

.max-width {
  max-width: 1000px;
  display: block;
  margin: auto;
}

.flex-space {
  flex: 1 1 auto;
}

.flex-even {
  flex: 1 1 0;
}

.not-desktop {
  display: none !important;
}

.desktop-only {
  display: block !important;
}

@media (max-width: 850px) {
  .not-desktop {
    display: block !important;
  }

  .desktop-only {
    display: none !important;
  }
}

.transactions-list {
  width: 100%;
  .mat-mdc-list-text {
    order: 2;
  }
  .mat-mdc-mdc-list-item-icon:last-of-type {
    order: 1;
  }
  .mat-mdc-mdc-list-item-icon:first-of-type {
    order: 3;
  }
}

.mat-icon.fs-1 {
  width: 40px;
  height: 40px;
  overflow: visible;
}

@media only screen and (max-width: 768px) {
  .mat-icon.fs-1 {
    width: 30px;
    height: 30px;
  }
}

.error-transactions {
  color: red;
  width: 100%;
  text-align: center;
}

.hr-light {
  height: 1px;
  width: 100%;
  background-color: #cbd5e1;
}

// .app-widget {
//   background-color: $componentBackground;
// }

.skeleton {
  .skeleton-item {
    overflow: hidden;
    position: relative;
    background-color: #dddbdd;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

// transactions page -> filters drawer
odin-transactions-list-page {
  position: relative !important;
}

.no-padding {
  .mat-mdc-mdc-dialog-container {
    padding: 0;
  }
}

::ng-deep {
  .monek-input.mat-mdc-mdc-form-field {
    .mat-mdc-select-arrow-wrapper {
      transform: none !important;
    }
  }
}

// BOOTSTRAP OVERRIDES

.card {
  background-color: transparent !important;
}

//

/* FONTS */
.fs-md-36 {
  font-size: 36px !important;
}

.fs-md-35 {
  font-size: 35px !important;
}

.fs-md-34 {
  font-size: 34px !important;
}

.fs-md-33 {
  font-size: 33px !important;
}

.fs-md-32 {
  font-size: 32px !important;
}

.fs-md-31 {
  font-size: 31px !important;
}

.fs-md-30 {
  font-size: 30px !important;
}

.fs-md-29 {
  font-size: 29px !important;
}

.fs-md-28 {
  font-size: 28px !important;
}

.fs-md-27 {
  font-size: 27px !important;
}

.fs-md-26 {
  font-size: 26px !important;
}

.fs-md-25 {
  font-size: 25px !important;
}

.fs-md-24 {
  font-size: 24px !important;
}

.fs-md-23 {
  font-size: 23px !important;
}

.fs-md-22 {
  font-size: 22px !important;
}

.fs-md-21 {
  font-size: 21px !important;
}

.fs-md-20 {
  font-size: 20px !important;
}

.fs-md-19 {
  font-size: 19px !important;
}

.fs-md-18 {
  font-size: 18px !important;
}

.fs-md-17 {
  font-size: 17px !important;
}

.fs-md-16 {
  font-size: 16px !important;
}

.fs-md-15 {
  font-size: 15px !important;
}

.fs-md-14 {
  font-size: 14px !important;
}

.fs-md-13 {
  font-size: 13px !important;
}

.fs-md-12 {
  font-size: 12px !important;
}

.fs-md-11 {
  font-size: 11px !important;
}

.fs-md-10 {
  font-size: 10px !important;
}

.fs-md-9 {
  font-size: 9px !important;
}

.fs-md-8 {
  font-size: 8px !important;
}

.fs-md-7 {
  font-size: 7px !important;
}

.fs-md-6 {
  font-size: 6px !important;
}

.fs-md-5 {
  font-size: 5px !important;
}

.fs-md-4 {
  font-size: 4px !important;
}

.fs-md-3 {
  font-size: 3px !important;
}

.fs-md-2 {
  font-size: 2px !important;
}

.fs-md-1 {
  font-size: 1px !important;
}

@media only screen and (max-width: 768px) {
  .fs-mb-70 {
    font-size: 70px !important;
  }

  .fs-mb-35 {
    font-size: 35px !important;
  }

  .fs-mb-34 {
    font-size: 34px !important;
  }

  .fs-mb-33 {
    font-size: 33px !important;
  }

  .fs-mb-32 {
    font-size: 32px !important;
  }

  .fs-mb-31 {
    font-size: 31px !important;
  }

  .fs-mb-30 {
    font-size: 30px !important;
  }

  .fs-mb-29 {
    font-size: 29px !important;
  }

  .fs-mb-28 {
    font-size: 28px !important;
  }

  .fs-mb-27 {
    font-size: 27px !important;
  }

  .fs-mb-26 {
    font-size: 26px !important;
  }

  .fs-mb-25 {
    font-size: 25px !important;
  }

  .fs-mb-24 {
    font-size: 24px !important;
  }

  .fs-mb-23 {
    font-size: 23px !important;
  }

  .fs-mb-22 {
    font-size: 22px !important;
  }

  .fs-mb-21 {
    font-size: 21px !important;
  }

  .fs-mb-20 {
    font-size: 20px !important;
  }

  .fs-mb-19 {
    font-size: 19px !important;
  }

  .fs-mb-18 {
    font-size: 18px !important;
  }

  .fs-mb-17 {
    font-size: 17px !important;
  }

  .fs-mb-16 {
    font-size: 16px !important;
  }

  .fs-mb-15 {
    font-size: 15px !important;
  }

  .fs-mb-14 {
    font-size: 14px !important;
  }

  .fs-mb-13 {
    font-size: 13px !important;
  }

  .fs-mb-12 {
    font-size: 12px !important;
  }

  .fs-mb-11 {
    font-size: 11px !important;
  }

  .fs-mb-10 {
    font-size: 10px !important;
  }

  .fs-mb-9 {
    font-size: 9px !important;
  }

  .fs-mb-8 {
    font-size: 8px !important;
  }

  .fs-mb-7 {
    font-size: 7px !important;
  }

  .fs-mb-6 {
    font-size: 6px !important;
  }

  .fs-mb-5 {
    font-size: 5px !important;
  }

  .fs-mb-4 {
    font-size: 4px !important;
  }

  .fs-mb-3 {
    font-size: 3px !important;
  }

  .fs-mb-2 {
    font-size: 2px !important;
  }

  .fs-mb-1 {
    font-size: 1px !important;
  }
}

::ng-deep {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled),
  .mat-mdc-form-field-focus-overlay {
    background: transparent !important;
  }
}
